import { i18n } from 'src/boot/i18n'
export const EXPERIENCE_URL = import.meta.env.VITE_SOURCE_EXPERIENCE_URL || 'https://experience.sourcesync.io'
export const STANDALONE_METACONTENT_URL = import.meta.env.VITE_SOURCE_STANDALONE_METACONTENT_URL || 'https://sam.sourcesync.io/#/'
export const VAST_TAG_TESTER_LINK = import.meta.env.VITE_VAST_TAG_TESTER_LINK || 'https://developer-tools.jwplayer.com/ad-tester'
export const VAST_TAG_PREFIX = import.meta.env.VITE_VAST_TAG_PREFIX || 'https://vast.sourcesync.io'
export const VAST_TAG_ID = import.meta.env.VITE_VAST_TAG_ID || 'prod.cloudfunctions.vast'
export const MONITORING_API_URL = import.meta.env.VITE_MONITORING_API_URL || 'https://platform-dev.sourcesync.io/monitor/acrfeed/status'

export const defaultNetworks = [
  {
    network: 'website',
    name: 'Website',
    icon: 'fas fa-globe-americas',
    brandcolor: '#caccd1',
    textcolor: '#52565e'
  },
  {
    network: 'facebook',
    name: 'Facebook',
    icon: 'fab fa-facebook-f',
    brandcolor: '#1877f2',
    textcolor: 'white',
    prefix: 'https://facebook.com/',
    shareable: true
  },
  {
    network: 'x',
    name: 'X',
    icon: 'fab fa-x-twitter',
    brandcolor: '#000000',
    textcolor: 'white',
    prefix: 'https://x.com/',
    shareable: true
  },
  {
    network: 'youtube',
    name: 'YouTube',
    icon: 'fab fa-youtube',
    brandcolor: '#ff0000',
    prefix: 'https://youtube.com/',
    textcolor: 'white'
  },
  {
    network: 'instagram',
    name: 'Instagram',
    icon: 'fab fa-instagram',
    brandcolor: '#c13584',
    prefix: 'https://instagram.com/',
    textcolor: 'white'
  },
  {
    network: 'linkedin',
    name: 'LinkedIn',
    icon: 'fab fa-linkedin-in',
    brandcolor: '#0077b5',
    textcolor: 'white',
    prefix: 'http://linkedin.com/',
    shareable: true
  },
  {
    network: 'discord',
    name: 'Discord',
    icon: 'fab fa-discord',
    brandcolor: '#7289da',
    prefix: 'https://discord.com/',
    textcolor: 'white'
  },
  {
    network: 'reddit',
    name: 'Reddit',
    icon: 'fab fa-reddit-alien',
    brandcolor: '#ff4500',
    prefix: 'https://www.reddit.com/',
    textcolor: 'white',
    shareable: true
  },
  {
    network: 'email',
    name: 'Email',
    icon: 'fas fa-envelope-open-text',
    brandcolor: '#E0218A',
    textcolor: 'white',
    shareable: true
  },
  {
    network: 'clipboard',
    name: 'Copy Link',
    icon: 'fas fa-link',
    brandcolor: '#E0218A',
    textcolor: 'white',
    shareable: true
  }
]

export const beacons = [
  // { name: 'google', label: 'Google Analytics' },
  // { name: 'facebook', label: 'Facebook Pixel' },
  {
    name: 'mobiquity',
    label: 'Mobiquity Pixel'
    // fields: [
    //   { type: 'json', slug: 'settings', label: 'Settings' },
    //   { type: 'text', slug: 'accountId', label: 'Account ID', required: true }
    // ]
  }
  // // { name: 'beeswax', label: 'Beeswax Beacon' }
]

/**
 * @typedef {object} ContentBehavior
 * @property {string} label the name of the behavior attribute for settings and i18n
 * @property {boolean} default the default value of the behavior
 * @property {boolean} isActivationEnabled whether or not the behavior may be toggled at that SAM/Activation level
 * @property {string} tooltip string value for the tooltip
 * @property {({[k: string]: boolean}) => boolean} [disable]
 */

/**
* Get list of content behaviors
*
* @param {boolean} [isActivationEnabled=false]
* @return {ContentBehavior[]}
*/
export const contentBehaviors = (isActivationEnabled = false) => {
  return [
    { label: 'overrideActivationBehaviors', default: false, hide: () => isActivationEnabled, tooltip: i18n.t('pages.editContent.overrideActivationBehaviors') },
    {
      label: 'autoPlay',
      default: false,
      hide: () => isActivationEnabled,
      tooltip: i18n.t('pages.editContent.autoPlayBehavior')
    },
    { label: 'muted', default: false, hide: () => isActivationEnabled, tooltip: i18n.t('pages.editContent.muted') },
    { label: 'clickOutsideToCloseSam', default: false, tooltip: i18n.t('pages.editContent.clickOutsideToCloseSam') },
    { label: 'playThroughDetails', default: false, tooltip: i18n.t('pages.editContent.playThroughBehavior') },
    { label: 'fullscreenDetailsInMobilePortrait', default: !isActivationEnabled, tooltip: i18n.t('pages.editContent.fullscreenDetailsInMobilePortrait') },
    { label: 'contentWallet', default: false, hide: () => isActivationEnabled, tooltip: i18n.t('pages.editContent.contentWalletBehavior') },
    { label: 'showPreviewThroughDetails', default: false, tooltip: i18n.t('pages.editContent.showPreviewThroughDetails') },
    { label: 'forcePortraitOrientation', default: false, hide: () => isActivationEnabled, tooltip: i18n.t('pages.editContent.forcePortraitOrientation') }
  ]
}

export const defaultTheme = {
  primary: '#0791CB',
  secondary: '#dc9a0d',
  accent: '#9C27B0',
  positive: '#21BA45',
  negative: '#C10015',
  info: '#31CCEC',
  warning: '#F2C037'
}

export const defaultDisplaySettings = {
  duration: 30000,
  concurrentCount: 1,
  interval: 0,
  position: 'right'
}

export const defaultTimelineFormat = () => {
  return {
    timeline: {
      tags: { items: [] },
      activations: { items: [] }
    }
  }
}

/**
 * This is a list of available activation position options that
 * should be used throught the system.
 *
 * @return {{label: string, value: string}[]}
 */
export const defaultPositionOptions = () => {
  return [
    // TODO: un-comment these when we support these positions
    // { label: 'Top Left', value: 'internal-top-left' },
    // { label: 'Top Right', value: 'internal-top-right' },
    // { label: 'Bottom Left', value: 'internal-bottom-left' },
    // { label: 'Bottom Right', value: 'internal-bottom-right' },
    // { label: 'Below Experience', value: 'external-bottom' },

    // TODO: remove these when we no longer support these positions
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
    { label: 'Bottom', value: 'bottom' }
  ]
}

export const distributionTypeOptions = ['overlay', 'video', 'dynamic']
export const productionTypeOptions = [
  { label: 'Video', value: 'video' },
  { label: 'Live', value: 'live' },
  { label: 'Ad Unit', value: 'ad_unit' },
  { label: 'Page', value: 'page' },
  { label: 'Channel', value: 'channel' },
  { label: 'Audio', value: 'audio' }
]
export const productionMediaType = [
  { label: 'Media Link', type: 'video', slug: 'media' },
  { label: 'Media Link', type: 'live', slug: 'media' },
  { label: 'Media Link', type: 'ad_unit', slug: 'media' },
  { label: 'Page URL', type: 'page', slug: 'media' },
  { label: 'Channel ID', type: 'channel', slug: 'media' },
  { label: 'Media Link', type: 'audio', slug: 'media' }
]
export const activationTypeOptions = [
  { label: 'Content Enhancement', value: 'contentEnhancement' },
  { label: 'Advertisement', value: 'advertisement' }
]
export const templateTypeOptions = [
  { label: 'None', value: null },
  { label: 'Details', value: 'details' }
]
